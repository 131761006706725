<app-nav class="nav" [admin]="currentUser.admin"></app-nav>
<div class="container" *ngIf="!loading">
  <header>
    <h1>Users</h1>
    <button (click)="addClick()">Add User</button>
  </header>
  <table>
    <thead>
      <tr>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Email</th>
        <th>Date Created</th>
        <th>Edit</th>
        <th>Delete</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of users">
        <td class="fullName">{{ user.firstName }} {{ user.lastName }}</td>
        <td class="firstName">{{ user.firstName }}</td>
        <td class="lastName">{{ user.lastName }}</td>
        <td class="email">{{ user.email }}</td>
        <td class="date">{{ timeFormat(user.created) }}</td>
        <td class="edit">
          <fa-icon
            class="icon"
            [icon]="faEdit"
            routerLink="/editUser/{{ user._id }}"
          ></fa-icon>
        </td>
        <td class="delete">
          <fa-icon
            class="icon"
            [icon]="faTrashCan"
            (click)="deleteClick(user)"
          ></fa-icon>
        </td>
      </tr>
    </tbody>
  </table>
</div>
