<app-nav class="nav" [admin]="currentUser.admin"></app-nav>
<div class="container" *ngIf="!loading">
  <header>
    <h1>Check Out</h1>
    <h2>Items</h2>
  </header>
  <table>
    <thead>
      <tr>
        <th>Title</th>
        <th>Author</th>
        <th>Image</th>
        <th>Price</th>
        <th>Quantity</th>
        <th>Total</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let cartItem of cartItems">
        <td class="title">{{ cartItem.book.title }}</td>
        <td class="author">{{ cartItem.book.author }}</td>
        <td class="img">
          <img
            src="{{ cartItem.book.img }}"
            alt=""
            *ngIf="cartItem.book.img"
          /><fa-icon
            class="default"
            [icon]="faFileImage"
            *ngIf="!cartItem.book.img"
          ></fa-icon>
        </td>
        <td class="price">${{ cartItem.book.price }}</td>
        <td class="quantity">{{ cartItem.quantity }}</td>
        <td class="sum">
          ${{ formatNumber(cartItem.book.price * cartItem.quantity) }}
        </td>
      </tr>
    </tbody>
  </table>
  <div
    class="orderDetails"
    [ngStyle]="{
      'background-color': cartItems.length % 2 == 0 ? '#001c57' : 'transparent'
    }"
  >
    <br />
    <h2>Order Summary</h2>
    <p>Subtotal: ${{ formatNumber(subtotal) }}</p>
    <p>Taxes & Fees: ${{ taxes }}</p>
    <p>Shipping: ${{ shipping }}</p>
    <p>Order Total: ${{ formatNumber(subtotal + taxes + shipping) }}</p>
    <button (click)="orderClick($event.target)">Order Now</button>
  </div>
</div>
