<app-nav class="nav" [admin]="currentUser.admin"></app-nav>
<!-- <div class="container" id="loading" [hidden]="!loading">
  <fa-icon [icon]="faLoad"></fa-icon>
</div> -->
<div class="container" *ngIf="!loading">
  <header>
    <h1>Order History</h1>
  </header>
  <table>
    <thead>
      <tr>
        <th>ID</th>
        <th>Number of Items</th>
        <th>Order Total</th>
        <th>Date Purchased</th>
        <th>Order Details</th>
        <th *ngIf="currentUser.admin">Delete</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let order of orders">
        <td class="id">{{ order._id }}</td>
        <td class="items">{{ order.items.length }}</td>
        <td class="total">${{ order.orderTotal }}</td>
        <td class="date">{{ timeFormat(order.created) }}</td>
        <td
          class="info"
          [ngStyle]="{
            'text-align': currentUser.admin ? 'right' : 'center',
            'grid-column': currentUser.admin ? '1 / 2' : '1 / 3'
          }"
        >
          <fa-icon
            class="icon"
            [icon]="faInfo"
            routerLink="/orderDetails/{{ order._id }}"
          ></fa-icon>
        </td>
        <td class="delete" *ngIf="currentUser.admin">
          <div (click)="deleteClick(order)">
            <p *ngIf="order.confirm">Confirm</p>
            <fa-icon
              class="icon"
              [icon]="order.confirm ? faCheck : faTrashAlt"
            ></fa-icon>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <h1 *ngIf="noOrders">No Orders</h1>
</div>
