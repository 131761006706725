<app-nav class="nav" [admin]="currentUser.admin"></app-nav>
<div class="container">
  <header>
    <h1>Add Book</h1>
  </header>
  <form (ngSubmit)="onSubmit()">
    <div class="form-control">
      <label for="title">Title</label>
      <input type="text" [(ngModel)]="title" name="title" id="title" />
    </div>
    <div class="form-control">
      <label for="author">Author</label>
      <input type="text" name="author" [(ngModel)]="author" id="author" />
    </div>
    <div class="form-control">
      <label for="description">Description</label>
      <textarea
        type="text"
        name="description"
        [(ngModel)]="description"
        id="description"
        cols="30"
        rows="10"
      ></textarea>
    </div>
    <div class="form-control">
      <label for="price">Price</label>
      <input type="text" name="price" [(ngModel)]="price" id="price" />
    </div>
    <div class="form-control">
      <label for="image">Image</label>
      <img [src]="url" />
      <input
        #image
        type="file"
        name="image"
        id="image"
        accept="image/*"
        (change)="onSelectFile($event)"
      />
      <p *ngIf="filesize">File Size: {{ filesize }}</p>
    </div>
    <input type="submit" value="{{submitValue}}" />
  </form>
</div>
