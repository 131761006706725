<app-nav class="nav" [admin]="currentUser.admin"></app-nav>
<div class="container" *ngIf="!loading">
  <header>
    <h1>Order #{{ order._id }}</h1>
    <h2>Order Placed {{ timeFormat(order.created) }}</h2>
  </header>
  <table>
    <thead>
      <tr>
        <th>Title</th>
        <th>Author</th>
        <th>Image</th>
        <th>Price</th>
        <th>Quantity</th>
        <th>Total</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let book of books">
        <td class="title">{{ book.title }}</td>
        <td class="author">{{ book.author }}</td>
        <td class="img">
          <img src="{{ book.img }}" alt="" *ngIf="book.img" /><fa-icon
            class="default"
            [icon]="faFileImage"
            *ngIf="!book.img"
          ></fa-icon>
        </td>
        <td class="price">${{ book.price }}</td>
        <td class="quantity">{{ book.quantity }}</td>
        <td class="sum">${{ book.price * book.quantity }}</td>
      </tr>
    </tbody>
  </table>
  <div
    class="orderDetails"
    [ngStyle]="{
      'background-color': books.length % 2 == 0 ? '#001c57' : 'transparent'
    }"
  >
    <br />
    <h2>Order Summary</h2>
    <p>Subtotal: ${{ order.subtotal }}</p>
    <p>Taxes & Fees: ${{ order.taxes }}</p>
    <p>Shipping: ${{ order.shipping }}</p>
    <p>Order Total: ${{ order.subtotal + order.taxes + order.shipping }}</p>
    <button (click)="deleteClick($event.target)">Cancel Order</button>
  </div>
</div>
